<template>
  <span :class="chipClass">
    <Icon v-if="icon" :icon="icon" :size="IconSize.SM" class="mr-1 -ml-1" />
    <slot />
    <Btn
      v-if="props.closable"
      :size="BtnSize.SM"
      class="ml-1"
      icon
      @click="emit('close')"
    >
      <Icon :icon="CloseSvg" :size="IconSize.SM" />
    </Btn>
  </span>
</template>

<script setup lang="ts">
import { Btn, BtnSize } from '~/components/btn';
import { Icon, IconSize } from '~/components/icon';
import CloseSvg from '~/assets/icons/close.svg?component';
import { ChipColor, ChipSize, ChipVariant } from './chip.constants';
import { computed } from 'vue';

const filledChipStyleVariantMap: Record<ChipColor, string> = {
  [ChipColor.PRIMARY]: 'bg-primary text-on-primary',
  [ChipColor.SECONDARY]: 'bg-secondary text-on-secondary',
  [ChipColor.TERTIARY]: 'bg-tertiary text-on-tertiary',
  [ChipColor.INFO]: 'bg-info text-on-primary',
  [ChipColor.ERROR]: 'bg-error',
  [ChipColor.SUCCESS]: 'bg-success',
};

const outlinedChipStyleVariantMap: Record<ChipColor, string> = {
  [ChipColor.PRIMARY]: 'border border-primary text-primary',
  [ChipColor.SECONDARY]: 'border border-secondary text-secondary',
  [ChipColor.TERTIARY]: 'border border-tertiary text-tertiary',
  [ChipColor.INFO]: 'border border-info text-primary',
  [ChipColor.ERROR]: 'border border-error text-error',
  [ChipColor.SUCCESS]: 'border border-success text-success',
};

const chipSizeStyleMap: Record<ChipSize, string> = {
  [ChipSize.SM]: 'h-6 text-xs',
  [ChipSize.MD]: 'h-8',
  [ChipSize.LG]: 'h-10',
};

const chipVariantStyleMap: Record<ChipVariant, Record<ChipColor, string>> = {
  [ChipVariant.FILLED]: filledChipStyleVariantMap,
  [ChipVariant.OUTLINE]: outlinedChipStyleVariantMap,
};

type Props = {
  size?: ChipSize;
  color?: ChipColor;
  variant?: ChipVariant;
  icon?: object;
  closable?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  size: ChipSize.MD,
  color: ChipColor.PRIMARY,
  variant: ChipVariant.OUTLINE,
  closable: false,
});

const emit = defineEmits(['close']);

const chipClass = computed(() => {
  return [
    'inline-flex rounded-full items-center font-semibold text-sm',
    chipSizeStyleMap[props.size],
    chipVariantStyleMap[props.variant][props.color],
    props.closable ? 'pl-4 pr-2' : 'px-4',
  ];
});
</script>
