export enum ChipSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum ChipColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum ChipVariant {
  FILLED = 'filled',
  OUTLINE = 'outline',
}
